import { render, staticRenderFns } from "./SbSpacer.vue?vue&type=template&id=1c03aa12&scoped=true"
import script from "./SbSpacer.vue?vue&type=script&lang=js"
export * from "./SbSpacer.vue?vue&type=script&lang=js"
import style0 from "./SbSpacer.vue?vue&type=style&index=0&id=1c03aa12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c03aa12",
  null
  
)

export default component.exports